import React from 'react';

import CustomBannerContainer from '@components/common/customBanners/common/Container';
import ErrorBoundary from '@components/common/ErrorBoundary';
import { saleTagStyles } from '../../../ProductFullResponsive/styles';

import { ICustomBanner, ISaleTagAttributes } from '@interfaces/customBanners/actions';

interface ISaleTagProps {
  tag: ICustomBanner;
  defaultView?: React.ReactNode;
}

const SaleTag: React.FunctionComponent<ISaleTagProps> = ({ defaultView, tag }) => {

  const saleTagClasses = saleTagStyles();

  return (
    <ErrorBoundary>
      {tag ? (
        <CustomBannerContainer
          className={saleTagClasses.saleTag}
          banner={tag}
          defaultView={defaultView}
        >
          {(tag.attributes as ISaleTagAttributes).text}
        </CustomBannerContainer>
      ) : defaultView }
    </ErrorBoundary>
  );
};

export default SaleTag;
