import { useSelector } from 'react-redux';

import { currency } from '@constants/currency';
import { IProductPrice } from '@interfaces/product';
import { IStore } from '@interfaces/store';

interface IUsePriceDataResult {
  currencyValue: string;
  withDiscount: boolean;
  withDiscountCustomerOnly: boolean;
  discountPrice: string;
  price: string;
}

export const usePriceData = (price: IProductPrice | undefined): IUsePriceDataResult => {

  const isAuthorized = useSelector((state: IStore) => state.auth.isAuthorized);
  const salePriceSwitch = useSelector((state: IStore) => state.page.salePriceSwitch);
  const salePriceSwitchCampaign = useSelector<IStore, string[]>((state: IStore) => state.page.salePriceSwitchCampaign);


  const productCampaignId = price?.campaignId || null;
  const campaignMinPurchasePrice = price?.campaignMinPurchasePrice || null;
  const isSaleSwitchActive: boolean = (salePriceSwitch && Boolean(campaignMinPurchasePrice)) && (salePriceSwitchCampaign.length === 0 || (Boolean(productCampaignId) && salePriceSwitchCampaign.includes(productCampaignId + '')));


  const discountPrice = campaignMinPurchasePrice ? (isSaleSwitchActive && price?.priceDiscount ? price.priceDiscount : '') : (price?.priceDiscount || '');
  // const discountPrice = (!campaignMinPurchasePrice || salePriceSwitch) ?  price?.priceDiscount || '' : '';
  const priceDiscountCustomerOnly = price?.priceDiscountCustomerOnly || '';

  const productWithDiscount = parseFloat(discountPrice || '0') > 0;
  const productWithDiscountCustomerOnly = !productWithDiscount && parseFloat(priceDiscountCustomerOnly || '0') > 0;

  const currencyName = price?.currencyName || '';
  const currencyValue = currencyName ? currency[currencyName] : currency.EUR;


  return {
    currencyValue,
    discountPrice: productWithDiscount ? discountPrice : priceDiscountCustomerOnly,
    price: price?.price || '',
    withDiscount: productWithDiscount || (isAuthorized && productWithDiscountCustomerOnly),
    withDiscountCustomerOnly: !isAuthorized && !productWithDiscount && productWithDiscountCustomerOnly,
  };
};
