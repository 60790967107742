import React, { PropsWithChildren, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IProductPrice } from '@interfaces/product';
import { ICustomBanner, ISaleTagAttributes } from '@interfaces/customBanners/actions';
import { ECustomBannerType } from '@constants/customBanners';
import SaleTag from '@components/common/customBanners/types/SaleTag';
import ErrorBoundary from '@components/common/ErrorBoundary';
import { IStore } from '@interfaces/store';

interface IProductImageAvailabilityProps extends PropsWithChildren {
  campaignId: number | undefined | null;
  productPrice: IProductPrice;
}

const ProductImageAvailabilitySaleCheck: React.FunctionComponent<IProductImageAvailabilityProps> = ({
  campaignId,
  children,
  productPrice,
}) => {

  const filterCampaign = (banner: ICustomBanner): boolean => {
    const bannerCampaignIds: string[] = (banner.attributes as ISaleTagAttributes).campaign_id || [];
    return bannerCampaignIds.includes(String(campaignId));
  };

  const filterCampaignEmpty = (banner: ICustomBanner): boolean => {
    const bannerCampaignIds: string[] = (banner.attributes as ISaleTagAttributes).campaign_id || [];
    return bannerCampaignIds.length === 0;
  };

  const customBannersOther = useSelector<IStore, ICustomBanner[] | undefined>((state: IStore) => state.pageData.data.customBanners?.other);

  const saleTags = useMemo(
    () => customBannersOther?.filter(item => item.type === ECustomBannerType.saletag) || [],
    [customBannersOther],
  );

  const tagToShow = useMemo(() => {
    if (campaignId) {
      return saleTags.find(filterCampaign) || saleTags.find(filterCampaignEmpty);
    }
    return saleTags.find(filterCampaignEmpty);
  }, [saleTags, campaignId]);

  // Todo need to improve
  return productPrice.campaignMinPurchasePrice ? (
    <ErrorBoundary>
      {tagToShow ? (<SaleTag tag={tagToShow} />)
        : (<>
          {children}
        </>)}
    </ErrorBoundary>
  ) : null;
};

export default ProductImageAvailabilitySaleCheck;
