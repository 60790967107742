import { createStyles, makeStyles } from '@mui/styles';

import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';
import { zIndex } from '@themes/zIndex';

export default makeStyles((theme) =>
  createStyles({
    likeIcon: {
      '&$likeIconBottom': {
        '& svg': {
          width: '12px',
        },
        height: '24px',
        width: '24px',
      },
      '&$likeIconTop': {
        '& svg': {
          width: '16px',
        },
        height: '32px',
        width: '32px',
      },
      '&:active svg path': {
        stroke: ColorsPalette.hoverActionCTA,
      },
      '&:hover svg path': {
        fill: ColorsPalette.hoverActionCTA,
      },
      alignItems: 'center',
      background: 'rgba(255, 255, 255, 0.81)',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
    },
    likeIconBlur: {
      [theme.breakpoints.up('md')]: {
        backdropFilter: 'blur(16px)',
      },
    },
    likeIconBottom: {},
    likeIconTop: {},
    likeIconWrap: {
      '&$likeIconBottom': {
        left: 'calc(100% - 44px)',
        marginTop: -48,
      },
      '&$likeIconTop': {
        left: 'calc(100% - 54px)',
        marginTop: 4,
        zIndex: zIndex.headerAction,
      },
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      padding: 8,
      position: 'absolute',
    },
    stockStatus: {
      alignItems: 'center',
      background: 'rgba(255, 255, 255, 0.81)',
      borderRadius: '100px',
      color: ColorsPalette.primary,
      display: 'flex',
      fontFamily: FontCollection.raleway,
      fontSize: 12,
      fontWeight: 500,
      height: '24px',
      justifyContent: 'center',
      left: 5,
      lineHeight: '160%',
      marginTop: -28,
      padding: '0 10px',
      position: 'absolute',
    },
    stockStatusBlur: {
      [theme.breakpoints.up('md')]: {
        backdropFilter: 'blur(16px)',
      },
    },
  }),
);
