import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import ErrorBoundary from '@components/common/ErrorBoundary';
import { usePriceData } from '@hooks/usePriceData';
import { calculateDiscountPercent } from '@components/pages/productsList/utils';
import { useTranslation } from '@hooks/useTranslation';

import { IProductInList } from '@interfaces/product';
import { IStore } from '@interfaces/store';
import { ISiteSettings } from '@interfaces/pageData/reducer';

import itemStyles from '../styles';

interface IProductImageTopActionsProps {
  product: IProductInList;
  currentProductId: number;
  rightAction?: ReactNode;
}

const ProductImageTopActions: React.FunctionComponent<IProductImageTopActionsProps> = ({
  product,
  currentProductId,
  rightAction,
}) => {
  const outletTag = useSelector<IStore, ISiteSettings['enable30dayPrice'] | undefined>((state: IStore) => state.pageData.data.siteSettings?.outletTag);
  const { t } = useTranslation();

  const isFresh = product.fresh;
  const currentColor = product.colors.find((colorItem) => String(colorItem.productId) === String(currentProductId)) || product.colors[0];
  const isOutletProduct = currentColor?.outlet || false;

  const { withDiscount } = usePriceData(currentColor?.price);

  const discountPercent = withDiscount ? calculateDiscountPercent(currentColor?.price) : null;
  const hasDiscountPrice = withDiscount && discountPercent;
  const discountText = isOutletProduct && outletTag ? 'Outlet' : `-${discountPercent}%`;

  const classesItem = itemStyles();

  return (
    <ErrorBoundary>
      {(rightAction || isFresh || hasDiscountPrice || isOutletProduct) && (
        <div className={classesItem.productFullTopAction}>
          {hasDiscountPrice && (
            <div
              className={classNames(classesItem.tag, classesItem.discountTag, classesItem.tagBlur)}>{discountText}</div>
          )}
          {isFresh && <div
            className={classNames(classesItem.tag, classesItem.freshTag, classesItem.tagBlur)}>{t('product.freshTag')}</div>}
          {rightAction && rightAction}
        </div>
      )}
    </ErrorBoundary>
  );
};

export default ProductImageTopActions;
